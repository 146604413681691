import React, { useState } from 'react';
import styled from 'styled-components';

import ring from 'assets/sound_bellRing.mp3';
import phoneIcon from 'assets/phone_icon.svg';
import emailIcon from 'assets/email_icon.svg';
import mapIcon from 'assets/map_icon.svg';
import upArrow from 'assets/up_arrow.svg';

const ContactCard = () => {
    const [showCard, setShowCard] = useState(false);

    let audio = new Audio(ring);

    const start = () => {
        audio.play();
        setShowCard(true);
    };

    const close = () => {
        setShowCard(false);
    };

    return (
        <CardSpacer>
            <CardContainer shown={showCard}>
                <Row>
                    <CardIcon src={phoneIcon} />
                    <ContactInfoContainer>
                        <CardText>Dial</CardText>
                        <CardLink href="tel:1-503-222-7999">
                            503.222.7999
                        </CardLink>
                    </ContactInfoContainer>
                </Row>
                <Row>
                    <CardIcon src={emailIcon} />
                    <ContactInfoContainer>
                        <CardText>Email</CardText>
                        <CardLink
                            href="mailto:inquire@twentyfour7.com"
                            tabindex="-1"
                            target="_blank"
                            rel="noreferrer"
                        >
                            INQUIRE@TWENTYFOUR7.COM
                        </CardLink>
                    </ContactInfoContainer>
                </Row>
                <Row>
                    <CardIcon src={mapIcon} />
                    <ContactInfoContainer>
                        <CardText>Visit</CardText>
                        <CardLink
                            href="https://goo.gl/maps/CV2M1iALVfu"
                            target="_blank"
                            rel="noreferrer"
                        >
                            425 NE 9TH AVE. PORTLAND, OR 97232
                        </CardLink>
                    </ContactInfoContainer>
                </Row>
                <CardClose onClick={close}>
                    <CloseIcon src={upArrow} />
                </CardClose>
            </CardContainer>
            {!showCard ? (
                <BellTrigger
                    onClick={start}
                    aria-label="Contact card trigger"
                />
            ) : null}
        </CardSpacer>
    );
};

export default ContactCard;

const CardSpacer = styled.div`
    width: 100vw;
    display: flex;
    justify-content: center;
`;
const CardContainer = styled.div`
    display: ${props => (props.shown ? 'flex' : 'none')};
    flex-direction: column;
    top: 0;
    margin: 0 auto;
    width: 95%;
    background: white;
    z-index: 300;
    color: black;
    align-content: center;
    justify-content: space-between;
    padding-top: 24px;
    margin-bottom: auto;
    max-width: 600px;
    position: absolute;
    animation: easeDown 0.3s;

    @keyframes easeDown {
        0% {
            top: -50vh;
        }
        100% {
            top: 0;
        }
    }
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 1.5rem;
    padding: 10px 0 0 10%;
`;
const ContactInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 15%;
`;
const CardIcon = styled.img`
    height: 50px;
    width: 50px;
`;
const CardText = styled.h1`
    color: var(--dk-gray);
    font-family: var(--font-family-medium-condensed);
    font-size: 2.25rem;
    line-height: 1;
    margin-bottom: 2px;
    margin-top: 0px;
    text-transform: uppercase;
`;
const CardLink = styled.a`
    color: var(--gray);
    font-family: var(--font-family-medium-condensed);
    font-size: 1rem;
    line-height: 1;
    text-decoration: none;
    word-break: break-word;

    :hover {
      color: var(--primary);
    }
`;
const CardClose = styled.button`
    width: 100%;
    background-color: var(--primary);
    height: 52px;
    border: 1px solid var(--primary);
    display: flex;
    justify-content: center;
    margin-top: 24px;
    cursor: pointer;
`;
const CloseIcon = styled.img`
    align-self: center;
    height: 40px;
    width: 40px;
    filter: invert(100%) sepia(65%) saturate(2%) hue-rotate(128deg)
        brightness(104%) contrast(101%);
`;
const BellTrigger = styled.button`
    position: absolute;
    z-index: 2;
    top: 58%;
    height: 25%;
    width: 25%;
    background-color: transparent;
    border: none;
    cursor: pointer;

    @media (max-width: 600px) {
        width: 65%;
    }
`;
