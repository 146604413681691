import { createGlobalStyle } from 'styled-components';
import aglWoff2 from '../fonts/akzidgrtskprolig-webfont.woff2';
import aglWoff from '../fonts/akzidgrtskprolig-webfont.woff';
import aglTtf from '../fonts/akzidgrtskprolig-webfont.ttf';
import agmWoff2 from '../fonts/akzidgrtskpromed-webfont.woff2';
import agmWoff from '../fonts/akzidgrtskpromed-webfont.woff';
import agmTtf from '../fonts/akzidgrtskpromed-webfont.ttf';
import agmcWoff2 from '../fonts/akzidgrtskpromedcnd-webfont.woff2';
import agmcWoff from '../fonts/akzidgrtskpromedcnd-webfont.woff';
import agmcTtf from '../fonts/akzidgrtskpromedcnd-webfont.ttf';
import agbcWoff2 from '../fonts/akzidgrtskprobolcnd-webfont.woff2';
import agbcWoff from '../fonts/akzidgrtskprobolcnd-webfont.woff';
import agbcTtf from '../fonts/akzidgrtskprobolcnd-webfont.ttf';

const GlobalStyles = createGlobalStyle`
@font-face {
	font-family: 'Akzidenz-Gro Bold Condensed';
	src:	url(${agbcWoff2}) format('woff2'),
			url(${agbcWoff}) format('woff'),
			url(${agbcTtf}) format('truetype');
	font-weight: normal;
	font-style: normal;
}

// Akzidenz-Grotesk Medium
@font-face {
	font-family: 'Akzidenz-Gro Medium';
	src:	url(${agmWoff2}) format('woff2'),
			url(${agmWoff}) format('woff'),
			url(${agmTtf}) format('truetype');
	font-weight: normal;
	font-style: normal;
}

// Akzidenz-Grotesk Medium Condensed
@font-face {
	font-family: 'Akzidenz-Gro Medium Condensed';
	src:	url(${agmcWoff2}) format('woff2'),
			url(${agmcWoff}) format('woff'),
			url(${agmcTtf}) format('truetype');
	font-weight: normal;
	font-style: normal;
}

// Akzidenz-Grotesk Light
@font-face {
	font-family: 'Akzidenz-Gro Light';
	src:	url(${aglWoff2}) format('woff2'),
			url(${aglWoff}) format('woff'),
			url(${aglTtf}) format('truetype');
	font-weight: normal;
	font-style: normal;
}

//Awesome Slider customizations
.awssld__bullets button {
    width: 12px;
    height: 12px;
}

.awssld {
    --loader-bar-color: #ff4729;
    --organic-arrow-thickness: 2px;
    --organic-arrow-height: 85px;
    --slider-transition-duration: 450ms;
    --organic-arrow-color: rgba(239,76,51,1);
    --transition-bezier: cubic-bezier(0.5, 0.075, 0.25, 0.95);
    --content-background-color: transparent;
}

.awssld__controls__arrow-left::before, .awssld__controls__arrow-left::after, .awssld__controls__arrow-right::before, .awssld__controls__arrow-right::after {
    right: calc( 50% - ( 0.7071 * (var(--organic-arrow-height) + var(--organic-arrow-thickness)) ) / 2);
    transition: transform 0.25s ease-out 0s, background-color 0.15s ease-out 0s, -webkit-transform 0.25s ease-out 0s;
}

.awssld__controls__arrow-left::before, .awssld__controls__arrow-right::before {
    transform: rotate(0deg);
}

.awssld__controls__arrow-left::after, .awssld__controls__arrow-right::after {
    transform: rotate(0deg);
}

.awssld__controls button:hover .awssld__controls__arrow-right:before {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
}
.awssld__controls button:hover .awssld__controls__arrow-right:after {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
}

.awssld__controls button:hover .awssld__controls__arrow-left:before {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
}
.awssld__controls button:hover .awssld__controls__arrow-left:after {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
}

.awssld__content > img {
    filter: none;
    mix-blend-mode: normal;
}

//All other styles

:root {
    //Fonts
    --font-family-light: 'Akzidenz-Gro Light', Helvetica, Arial, sans-serif;
		--font-family-medium: 'Akzidenz-Gro Medium', Helvetica, Arial, sans-serif;
    --font-family-medium-condensed: 'Akzidenz-Gro Medium Condensed', Helvetica, Arial, sans-serif;
    --font-family-bold: 'Akzidenz-Gro Bold Condensed', Helvetica, Arial, sans-serif;

    //Colors
    --primary:       #ff4729;
    --primary-hover: #ff735c;
    --gray:          #777777;
    --lt-gray:       #e2e2e2;
    --dk-gray:       #0a0a0a;

    //Breakpoints
    --small:   768px;
    --medium:  992px;
    --large:   1024px;
    --xlarge:  1200px;
    --xxlarge: 1400px;
}

body, html {
    margin: 0;
    padding: 0;
}
*{
  box-sizing: border-box;
}

//Typography
h1, h2, h3, h4, h5 {
    font-family: var(--font-family-bold);
    text-transform: uppercase;
}

 h1 {
    font-size: 1.5rem;
    line-height: 1.3;

    @media (min-width: 768px) {
        font-size: 2rem;
        line-height: 1.2;
    }

    @media (min-width: 1024px) {
        font-size: 3.25rem;
    }
 }

 h2 {
    font-size: 2.8125rem;
 }

 h3 {
 		font-size: 1.875rem;
 }

 h4 {
 		font-size: 1.75rem;
 }

 h6 {
	 	font-family: var(--font-family-light);
 }

 p {
    color: var(--dk-gray);
    font-family: var(--font-family-light);
    font-size: 1rem;
    line-height: 1.6;

    @media (min-width: 768px) {
        font-size: 1.125rem;
    }
 }

 a {
	 color: var(--primary);
	 text-decoration: none;

	:hover {
		color: white;
		-webkit-transition: all 500ms ease-out;
		-moz-transition: all 500ms ease-out;
		-o-transition: all 500ms ease-out;
		transition: all 500ms ease-out;
	}

	&.reverseLinkColor {
		color: white;

		:hover {
			color: var(--primary);
		}
	}
 }
`;

export default GlobalStyles;
