import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import AwesomeSlider from 'react-awesome-slider';
import Styles from 'react-awesome-slider/src/styles';
import { AppContext } from 'components/Home';
import Loader from 'components/Loader';

import awardIcon from 'assets/award_icon.svg';
import rightArrow from 'assets/right_arrow.svg';
import leftArrow from 'assets/left_arrow.svg';

const client = axios.create({
    baseURL: 'https://staging.twentyfour7.com/wp-json/wp/v2'
});

const ProjectsFeed = () => {
    const { setShowModal, setModalContent } = useContext(AppContext);
    const [workFeed, setWorkFeed] = useState([]);
    const [awardsFeed, setAwardsFeed] = useState([]);
    const [projectsFeed, setProjectsFeed] = useState([]);
    const [sortedFeed, setSortedFeed] = useState([]);

    //Decodes html in response data where needed
    function decodeHtml(html) {
        var txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    }

    function detectURLs(message) {
        var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
        return message.match(urlRegex);
    }

    //Gets both work and award posts
    useEffect(() => {
        async function getProjects() {
            try {
                const response = await client.get('/work?per_page=100&embed');
                setWorkFeed(response.data);
            } catch (err) {
                console.log(err);
            }
        }
        async function getAwards() {
            try {
                const response = await client.get('/award?per_page=100&embed');
                setAwardsFeed(response.data);
            } catch (err) {
                console.log(err);
            }
        }
        getProjects();
        getAwards();
    }, []);

    //Once work and awards are populated, combines them into projects array
    useEffect(() => {
        setProjectsFeed(workFeed.concat(awardsFeed));
    }, [workFeed.length > 0 && awardsFeed.length > 0]);

    //Uses a referance array to sort the projects array and push to a new sorted feed
    useEffect(() => {
        const r = [
            0, 20, 1, 21, 2, 3, 4, 5, 6, 7, 8, 22, 9, 10, 11, 23, 12, 13, 24,
            25, 14, 15, 26, 16, 17, 18, 27, 19
        ];
        const a = [];
        for (let i = 0; i < projectsFeed.length; i++) {
            a.push(projectsFeed[r[i]]);
        }
        setSortedFeed(a);
    }, [projectsFeed.length > 0]);

    //Props for layout of the sorted feed
    const sortedProps = [
        {
            id: 0,
            height: '350px',
            size: 'calc(45vw - 4px)',
            position: 'left',
            maxWidth: 'calc(600px - 4px)'
        },
        {
            id: 1,
            height: '350px',
            size: 'calc(45vw - 4px)',
            position: 'right',
            maxWidth: 'calc(600px - 4px)'
        },
        {
            id: 2,
            height: '350px',
            size: '90vw',
            position: '',
            maxWidth: '1200px'
        },
        {
            id: 3,
            height: '350px',
            size: 'calc(45vw - 4px)',
            position: 'left',
            maxWidth: 'calc(600px - 4px)'
        },
        {
            id: 4,
            height: '350px',
            size: 'calc(45vw - 4px)',
            position: 'right',
            maxWidth: 'calc(600px - 4px)'
        },
        {
            id: 5,
            height: '350px',
            size: '90vw',
            position: '',
            maxWidth: '1200px'
        },
        {
            id: 6,
            height: '350px',
            size: 'calc(45vw - 4px)',
            position: 'left',
            maxWidth: 'calc(600px - 4px)'
        },
        {
            id: 7,
            height: '350px',
            size: 'calc(45vw - 4px)',
            position: 'right',
            maxWidth: 'calc(600px - 4px)'
        },
        {
            id: 8,
            height: '350px',
            size: '90vw',
            position: '',
            maxWidth: '1200px'
        },
        {
            id: 9,
            height: '350px',
            size: '90vw',
            position: '',
            maxWidth: '1200px'
        },
        {
            id: 10,
            height: '706px',
            size: 'calc(45vw - 4px)',
            position: 'left',
            maxWidth: 'calc(600px - 4px)'
        },
        {
            id: 11,
            height: '350px',
            size: 'calc(45vw - 4px)',
            position: 'right',
            maxWidth: 'calc(600px - 4px)'
        },
        {
            id: 12,
            height: '350px',
            size: 'calc(45vw - 4px)',
            position: 'right',
            maxWidth: 'calc(600px - 4px)'
        },
        {
            id: 13,
            height: '700px',
            size: '90vw',
            position: '',
            maxWidth: '1200px'
        },
        {
            id: 14,
            height: '350px',
            size: 'calc(45vw - 4px)',
            position: 'left',
            maxWidth: 'calc(600px - 4px)'
        },
        {
            id: 15,
            height: '350px',
            size: 'calc(45vw - 4px)',
            position: 'right',
            maxWidth: 'calc(600px - 4px)'
        },
        {
            id: 16,
            height: '700px',
            size: '90vw',
            position: '',
            maxWidth: '1200px'
        },
        {
            id: 17,
            height: '706px',
            size: 'calc(45vw - 4px)',
            position: 'right',
            maxWidth: 'calc(600px - 4px)'
        },
        {
            id: 18,
            height: '350px',
            size: 'calc(45vw - 4px)',
            position: 'left',
            maxWidth: 'calc(600px - 4px)'
        },
        {
            id: 19,
            height: '350px',
            size: 'calc(45vw - 4px)',
            position: 'left',
            maxWidth: 'calc(600px - 4px)'
        },
        {
            id: 20,
            height: '350px',
            size: '90vw',
            position: '',
            maxWidth: '1200px'
        },
        {
            id: 21,
            height: '706px',
            size: 'calc(45vw - 4px)',
            position: 'left',
            maxWidth: 'calc(600px - 4px)'
        },
        {
            id: 22,
            height: '350px',
            size: 'calc(45vw - 4px)',
            position: 'right',
            maxWidth: 'calc(600px - 4px)'
        },
        {
            id: 23,
            height: '350px',
            size: 'calc(45vw - 4px)',
            position: 'right',
            maxWidth: 'calc(600px - 4px)'
        },
        {
            id: 24,
            height: '700px',
            size: '90vw',
            position: '',
            maxWidth: '1200px'
        },
        {
            id: 25,
            height: '350px',
            size: 'calc(45vw - 4px)',
            position: 'left',
            maxWidth: 'calc(600px - 4px)'
        },
        {
            id: 26,
            height: '350px',
            size: 'calc(45vw - 4px)',
            position: 'right',
            maxWidth: 'calc(600px - 4px)'
        },
        {
            id: 27,
            height: '700px',
            size: '90vw',
            position: '',
            maxWidth: '1200px'
        }
    ];

    return (
        <Grid>
            {sortedFeed.length > 0 ? (
                //Only 26 entries are used currently
                sortedFeed.slice(0, 28).map((projects, i) => (
                    <Tile
                        key={projects.id}
                        background={projects.acf.module_bg_image}
                        height={sortedProps[i].height}
                        size={sortedProps[i].size}
                        position={sortedProps[i].position}
                        maxWidth={sortedProps[i].maxWidth}
                        award={!projects.acf.module_bg_image}
                        onClick={() => (
                            setShowModal(true),
                            setModalContent(
                                projects.acf.module_bg_image ? (
                                    <ModalContainer key={projects.id}>
                                        <Header>
                                            <ModalLogo
                                                src={
                                                    projects.acf
                                                        .client_logo_black
                                                }
                                                alt={decodeHtml(
                                                    projects.title.rendered
                                                )}
                                            />
                                            <TitleContainer>
                                                <Title>
                                                    {projects.acf.cs_header}
                                                </Title>
                                                <Subtitle>
                                                    {projects.acf.cs_sub_header}
                                                </Subtitle>
                                            </TitleContainer>
                                        </Header>
                                        <ModalSliderContainer>
                                            <ModalSlider
                                                bullets={false}
                                                key={
                                                    projects.acf.top_slideshow
                                                        .id
                                                }
                                                cssModule={Styles}
                                                organicArrows={false}
                                                buttonContentRight={
                                                    <Arrow
                                                        background={rightArrow}
                                                    />
                                                }
                                                buttonContentLeft={
                                                    <Arrow
                                                        background={leftArrow}
                                                    />
                                                }
                                            >
                                                {projects.acf.top_slideshow.map(
                                                    slide => (
                                                        <ModalSliderImageContainer>
                                                            <ModalSliderImage
                                                                src={slide.url}
                                                                alt={
                                                                    slide.filename
                                                                }
                                                            />
                                                        </ModalSliderImageContainer>
                                                    )
                                                )}
                                            </ModalSlider>
                                        </ModalSliderContainer>
                                        {projects.acf.cs_add_row.map(rows => (
                                            <>
                                                <RowsContainer>
                                                    {rows.text_row}
                                                </RowsContainer>
                                                {rows.video_link !== null ? (
                                                    <RowsFrame
                                                        width={
                                                            window.screen
                                                                .width / 3
                                                        }
                                                        height={
                                                            window.screen
                                                                .width / 5
                                                        }
                                                        frameBorder="0"
                                                        allow="autoplay; fullscreen; picture-in-picture"
                                                        src={detectURLs(
                                                            rows.video_link
                                                        )}
                                                    />
                                                ) : (
                                                    rows.image_row !==
                                                        false && (
                                                        <RowsImage
                                                            src={decodeHtml(
                                                                rows.image_row
                                                            )}
                                                            alt={
                                                                rows.media_type_choice
                                                            }
                                                        />
                                                    )
                                                )}
                                            </>
                                        ))}
                                    </ModalContainer>
                                ) : (
                                    <>
                                        <AwardContainer>
                                            <AwardModalIcon>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 175 175"
                                                >
                                                    <path d="M127.76,5V22.15h35v24A34.3,34.3,0,0,1,129.58,80.4l-3.31.11-1.19,3.09a40.77,40.77,0,0,1-29.38,25l-4,.83v16.28l3.41,1.15a23.91,23.91,0,0,1,15.78,17.67H64.1A23.91,23.91,0,0,1,79.87,126.9l3.41-1.15V109.48l-4-.83a40.77,40.77,0,0,1-29.38-25l-1.19-3.09-3.31-.11A34.3,34.3,0,0,1,12.23,46.18v-24h35V5h80.51m0,69,6-1.17a27.25,27.25,0,0,0,22-26.69v-17H127.76V74M47.24,74V29.23H19.31v17a27.25,27.25,0,0,0,22,26.69l6,1.17M132.76,0H42.24V17.15h-35v29a39.3,39.3,0,0,0,38,39.22,45.56,45.56,0,0,0,33,28.14v8.62a28.93,28.93,0,0,0-19.71,27.41h57.87a28.93,28.93,0,0,0-19.72-27.41v-8.62a45.56,45.56,0,0,0,33-28.14,39.3,39.3,0,0,0,38-39.22v-29h-35V0Zm0,68V34.23h17.93v12A22.23,22.23,0,0,1,132.76,68ZM42.24,68A22.23,22.23,0,0,1,24.31,46.18v-12H42.24V68Z"></path>
                                                    <path d="M124.74,163v7H50.26v-7h74.49m5-5H45.26v17h84.49V158Z"></path>
                                                </svg>
                                            </AwardModalIcon>
                                            <AwardTitle>
                                                {decodeHtml(
                                                    projects.acf.award_header
                                                )}
                                            </AwardTitle>
                                            <Subtitle>
                                                {decodeHtml(
                                                    projects.acf
                                                        .award_sub_header
                                                )}
                                            </Subtitle>
                                            <ModalBody>
                                                {decodeHtml(
                                                    projects.acf.award_content
                                                )}
                                            </ModalBody>
                                        </AwardContainer>
                                    </>
                                )
                            )
                        )}
                    >
                        {projects.acf.module_bg_image ? (
                            <ClientLogo
                                src={projects.acf.client_logo_white}
                                alt={decodeHtml(projects.title.rendered)}
                            />
                        ) : (
                            <AwardIcon
                                height={sortedProps[i].height}
                                size={sortedProps[i].size}
                                src={awardIcon}
                                alt={decodeHtml(projects.title.rendered)}
                            />
                        )}
                    </Tile>
                ))
            ) : (
                <Loader />
            )}
        </Grid>
    );
};

export default ProjectsFeed;

const Grid = styled.div`
    width: min-content;
`;
const Tile = styled.div`
    color: white;
    background: url(${props => props.background});
    background-color: ${props => (props.award ? 'var(--primary)' : '')};
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    margin: 3px;
    height: ${props => props.height};
    width: ${props => props.size};
    float: ${props => props.position};
    max-width: ${props => props.maxWidth};
    cursor: pointer;
    overflow: hidden;

    :after {
        content: ' ';
        position: absolute;
        height: ${props => props.height};
        width: ${props => props.size};
        max-width: ${props => props.maxWidth};
        background: rgba(0, 0, 0, 0.3);
        opacity: 1;
        transition: all 1s;
        -webkit-transition: all 1s;
    }

    :hover:after {
        cursor: pointer;
        opacity: 0;
    }
`;
const ClientLogo = styled.img`
    align-self: center;
    cursor: pointer;
    height: auto;
    max-width: 300px;
    z-index: 2;

    @media (max-width: 768px) {
        height: 150px;
        width: 150px;
    }
`;
const AwardIcon = styled.img`
    height: 50%;
    width: 50%;
    align-self: center;
    filter: invert(100%) sepia(65%) saturate(2%) hue-rotate(128deg)
        brightness(104%) contrast(101%);
    z-index: 2;
    cursor: pointer;
`;
const AwardContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
const AwardModalIcon = styled.div`
    height: 230px;
    width: 230px;
    margin: 50px auto;
`;
const AwardTitle = styled.h2``;
const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;
const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;

    img {
        margin-right: 30px;
    }

    @media (min-width: 768px) {
        flex-direction: row;
    }
`;
const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
const Title = styled.h2`
    color: var(--dk-gray);
    font-family: var(--font-family-medium-condensed);
    font-weight: 400;
    font-size: 2.5rem;
    padding: 0;
    margin: 0;

    @media (max-width: 768px) {
        font-size: 1.75rem;
        text-align: center;
    }
`;
const Subtitle = styled.h3`
    color: var(--gray);
    font-family: var(--font-family-medium-condensed);
    font-weight: 400;
    font-size: 1.5rem;
    font-weight: normal;
    margin: 0;

    @media (max-width: 768px) {
        font-size: 1.25rem;
        text-align: center;
    }
`;
const ModalSlider = styled(AwesomeSlider)`
    height: 500px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        height: 60vw;
        margin-bottom: 0px;
    }

    .awssld__next {
        right: -100px;

        @media (max-width: 1200px) {
            right: -6vw;
        }
        @media (max-width: 850px) {
            right: -3vw;
        }
        @media (max-width: 768px) {
            right: -5vw;
        }
    }
    .awssld__prev {
        left: -100px;

        @media (max-width: 1200px) {
            left: -6vw;
        }
        @media (max-width: 850px) {
            left: -3vw;
        }
        @media (max-width: 768px) {
            left: -5vw;
        }
    }
`;
const ModalSliderImage = styled.img`
    max-width: 90vw;

    @media (max-width: 1200px) {
        max-width: 85vw;
    }
`;
const ModalSliderImageContainer = styled.div``;
const ModalBody = styled.p`
    margin: 0;
`;
const ModalLogo = styled.img`
    height: 170px;
    width: 170px;
`;
const Arrow = styled.span`
    background-image: url(${props => props.background});
    height: 25px;
    width: 25px;
    position: fixed;

    :hover {
        filter: invert(38%) sepia(100%) saturate(2797%) hue-rotate(344deg)
            brightness(101%) contrast(102%);
    }
`;
const RowsContainer = styled.p`
    max-width: 90vw;
    margin-left: auto;
    margin-right: auto;
`;
const RowsImage = styled.img`
    max-width: 90vw;
`;
const RowsFrame = styled.iframe`
    width: 100%;

    @media (max-width: 500px) {
        height: auto;
    }
`;
const ModalSliderContainer = styled.div`
    width: 100%;
    display: flex;
`;
