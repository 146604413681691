import React, { useState, createContext } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import MainSlider from 'components/Slider';
import GlobalStyles from 'components/GlobalStyles';
import Modal from 'components/Modal';
import NoMatch from 'components/NoMatch';

export const AppContext = createContext();

const Home = () => {
    const [page, setPage] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState();
    const [hideNav, setHideNav] = useState(false);

    return (
        <Router>
            <GlobalStyles />
            <AppContext.Provider
                value={{
                    showModal,
                    setShowModal,
                    page,
                    setPage,
                    modalContent,
                    setModalContent,
                    hideNav,
                    setHideNav
                }}
            >
                <>
                    <Routes>
                        <Route
                            path="/"
                            element={<MainSlider pageNumber={0} />}
                        />
                        <Route
                            path="/About"
                            element={<MainSlider pageNumber={1} />}
                        />
                        <Route
                            path="/Thinking"
                            element={<MainSlider pageNumber={2} />}
                        />
                        <Route
                            path="/Projects"
                            element={<MainSlider pageNumber={3} />}
                        />
                        <Route
                            path="/Life"
                            element={<MainSlider pageNumber={4} />}
                        />
                        <Route
                            path="/Contact"
                            element={<MainSlider pageNumber={5} />}
                        />
                        <Route
                            path="/Careers"
                            element={<MainSlider pageNumber={6} />}
                        />
                        <Route path="*" element={<NoMatch />} />
                    </Routes>
                </>
                <Modal />
            </AppContext.Provider>
        </Router>
    );
};

export default Home;
