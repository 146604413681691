import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Footer from '../pages/Footer';

const NoMatch = () => {
    return (
        <Container>
            <ContentContainer>
                <Title>EPIC 404 — NOT FOUND</Title>
                <Message>
                    SORRY — THAT PAGE TOOK OFF EARLY. <br /> TRY THIS ONE.
                </Message>
                <BrandButton to="/">Dive Back In</BrandButton>
            </ContentContainer>
            <FooterContainer>
                <Footer />
            </FooterContainer>
        </Container>
    );
};

export default NoMatch;

const Container = styled.div`
    background-color: #3f3f3f;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    text-align: center;
`;
const Title = styled.h2``;
const BrandButton = styled(Link)`
    border: 3px solid var(--primary);
    color: var(--primary);
    font-size: 1.5rem;
    line-height: 1;
    padding: 12px 12px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    transform: perspective(1px) translateZ(0);
    -webkit-transform: perspective(1px) translateZ(0);
    transition: all 500ms ease-out;
    -webkit-transition: all 500ms ease-out;
    -moz-transition: all 500ms ease-out;
    -o-transition: all 500ms ease-out;
    transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    transition-property: color;
    -webkit-transition-property: color;
    width: 50vw;
    display: flex;
    align-items: justify;
    justify-content: space-evenly;

    :before {
        background: var(--primary);
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }

    :hover,
    :focus {
        color: white;

        :before {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
            position: absolute;
        }

        svg {
            transition: fill 0.3s ease-out;
            fill: white;
        }
    }

    @media (min-width: 992px) {
        font-size: 1.75rem;
        width: 25vw;
    }
`;
const ContentContainer = styled.div`
    margin: auto;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const Message = styled.p`
    color: white;
`;
const FooterContainer = styled.div`
    margin-top: auto;
    width: 100vw;
`;
