import React from 'react';
import styled from 'styled-components';

const ThinkingPage = () => {
    return (
        <Container>
            <Panel>
                <LargeMessage>
                    WE ARE ENTREPRENEURS — UNAFRAID OF HARD WORK, TOUGH
                    QUESTIONS OR HONEST ANSWERS. THEY ARE THE ONLY PATH TO{' '}
                    <span>BRAND TRUTHS.</span>
                </LargeMessage>
                <BrandButton
                    href="https://thinking.twentyfour7.com"
                    tabindex="-1"
                    target="_blank"
                    rel="noreferrer"
                >
                    LET'S GO
                </BrandButton>
            </Panel>
        </Container>
    );
};

export default ThinkingPage;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 100vh;
    width: 100vw;
`;
const Panel = styled.div`
    margin: auto;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const LargeMessage = styled.h1`
    color: ${props => props.color};
    text-align: center;
    margin: auto;
    align-items: center;
    max-width: 67%;

    @media (min-width: 1200px) {
        max-width: 50%;
    }

    span {
        color: var(--primary);
    }
`;

const BrandButton = styled.a`
    border: 3px solid var(--primary);
    color: var(--primary);
    font-family: var(--font-family-bold);
    font-size: 1.5rem;
    line-height: 1;
    margin-top: 40px;
    padding: 12px 28px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    transform: perspective(1px) translateZ(0);
    -webkit-transform: perspective(1px) translateZ(0);
    transition: all 500ms ease-out;
    -webkit-transition: all 500ms ease-out;
    -moz-transition: all 500ms ease-out;
    -o-transition: all 500ms ease-out;
    transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    transition-property: color;
    -webkit-transition-property: color;
    width: 200px;

    :before {
        background: var(--primary);
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }

    :hover,
    :focus {
        color: white;

        :before {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
            position: absolute;
        }
    }

    @media (min-width: 992px) {
        font-size: 1.75rem;
        margin-top: 70px;
        width: 300px;
    }
`;
