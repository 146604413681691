import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import AwesomeSlider from 'react-awesome-slider';
import Styles from 'react-awesome-slider/src/styles';
import { Link } from 'react-router-dom';
import { AppContext } from 'components/Home';

import LandingPage from 'pages/Landing';
import AboutPage from 'pages/About';
import ThinkingPage from 'pages/Thinking';
import ProjectsPage from 'pages/Projects';
import LifePage from 'pages/Life';
import ContactPage from 'pages/Contact';
import CareersPage from 'pages/Careers';
import ContactCard from 'components/ContactCard';

import preLoader from 'assets/loading_gif.gif';
import bgLanding from 'assets/bg_landing_2_large.jpeg';
import bgAbout from 'assets/bg_approach_1_large.jpeg';
import bgThinking from 'assets/bg_thinking_large.jpeg';
import bgProjects from 'assets/bg_work_1_large.jpeg';
import bgLife from 'assets/bg_life_1_large.jpeg';
import bgContact from 'assets/bg_contact_1_large.jpeg';
import bgCareers from 'assets/bg_careers_1_large.jpeg';
import tf7Logo from 'assets/logo_TF7_white.png';

const MainSlider = pageNumber => {
    const { hideNav, showModal, page, setPage } = useContext(AppContext);
    const [moving, setMoving] = useState(false);

    useEffect(() => {
        setPage(pageNumber.pageNumber);
        setMoving(true);
    }, [page]);

    useEffect(() => {
        setTimeout(() => {
            setMoving(false);
        }, 800);
    }, [page]);

    // prettier-ignore
    const screens = [
    { id: 0, name: '', content: <LandingPage />, background: bgLanding },
    { id: 1, name: 'About', content: <AboutPage />, background: bgAbout },
    { id: 2, name: 'Thinking', content: <ThinkingPage />, background: bgThinking },
    { id: 3, name: 'Projects', content: <ProjectsPage />, background: bgProjects },
    { id: 4, name: 'Life', content: <LifePage />, background: bgLife },
    { id: 5, name: 'Contact', content: <ContactPage />, background: bgContact },
    { id: 6, name: 'Careers', content: <CareersPage />, background: bgCareers },
];

    return (
        <>
            <Slider
                hideNav={hideNav}
                mobileTouch={false}
                showModal={showModal}
                cssModule={Styles}
                firstPage={page == 0}
                fillParent={true}
                bullets={false}
                infinite={false}
                startupScreen={<Preloader />}
                selected={page}
                customContent={
                    <>
                        {page !== 0 && (
                            <>
                                <Link to="/" onClick={() => setPage(0)}>
                                    <HeaderLogo
                                        hidenav={hideNav ? '-200px' : '30px'}
                                        src={tf7Logo}
                                        alt="TwentyFour 7 logo"
                                    />
                                </Link>
                                <Nav hidenav={hideNav ? '-200px' : '30px'}>
                                    {screens.map(nav => (
                                        <NavItem
                                            key={nav.id}
                                            onClick={
                                                !moving &&
                                                (() => setPage(nav.id))
                                            }
                                            selected={page == nav.id}
                                            to={!moving && '/' + nav.name}
                                            aria-label={
                                                nav.name == ''
                                                    ? 'Home'
                                                    : nav.name
                                            }
                                        >
                                            {nav.name}
                                        </NavItem>
                                    ))}
                                </Nav>
                                {page == 5 ? <ContactCard /> : null}
                            </>
                        )}
                    </>
                }
                organicArrows={false}
                buttonContentRight={
                    page !== 0 ? (
                        <ControlButtonRight
                            aria-label="Control button right"
                            hidenav={hideNav ? '-150px' : '0'}
                            className="awssld__controls__arrow-right"
                            onClick={() => setPage(page + 1)}
                            to={
                                page + 1 < screens.length
                                    ? '/' + screens[page + 1].name
                                    : '/' + screens[6].name
                            }
                        />
                    ) : (
                        <LandingControlButton
                            onClick={() => setPage(page + 1)}
                            to={'/' + screens[1].name}
                            aria-label="Control button right"
                        >
                            Ignite
                            <Arrow>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 30 30"
                                >
                                    <polygon points="8.56 0 6.44 2.12 19.32 15 6.44 27.88 8.56 30 23.56 15 8.56 0"></polygon>
                                </svg>
                            </Arrow>
                        </LandingControlButton>
                    )
                }
                buttonContentLeft={
                    <ControlButtonLeft
                        hidden={page == 0}
                        hidenav={hideNav ? '-150px' : '0'}
                        className="awssld__controls__arrow-left"
                        onClick={() => setPage(page - 1)}
                        to={page > 1 ? '/' + screens[page - 1].name : '/'}
                        aria-label="Control button left"
                    />
                }
            >
                {screens.map(screen => (
                    <Slide
                        key={screen.id}
                        data-src={screen.background}
                        data-alt={`${screen.name} - ${screen.id}`}
                    >
                        {screen.content}
                    </Slide>
                ))}
            </Slider>
        </>
    );
};

export default MainSlider;

//PreLoader
const Preloader = styled.div`
    background: #18191b url(${preLoader}) no-repeat center center fixed;
    -webkit-background-size: conatin;
    -moz-background-size: conatin;
    -o-background-size: conatin;
    background-size: conatin;
`;

//Slider
const Slider = styled(AwesomeSlider)`
    .awssld__next {
        height: ${props => (props.firstPage ? '0' : '')};
        width: ${props => (props.firstPage ? '0' : '')};
    }

    .awssld__content {
        overflow: ${props =>
            props.firstPage || props.showModal ? 'hidden' : 'auto'};

        > img {
            position: fixed;
            left: 0;
            top: 0;
        }
    }

    .awssld__prev {
        margin-left: ${props => (props.hideNav ? '-150px' : '')};
        -webkit-transition: all 500ms ease-out;
        -moz-transition: all 500ms ease-out;
        -o-transition: all 500ms ease-out;
        transition: all 500ms ease-out;
    }
    .awssld__next {
        margin-right: ${props => (props.hideNav ? '-150px' : '')};
        -webkit-transition: all 500ms ease-out;
        -moz-transition: all 500ms ease-out;
        -o-transition: all 500ms ease-out;
        transition: all 500ms ease-out;
    }
`;
const Slide = styled.div`
    position: absolute;
`;

//Navigation
const Nav = styled.nav`
    display: flex;
    position: absolute;
    right: 30px;
    top: ${props => props.hidenav};
    z-index: 2;

    -webkit-transition: all 500ms ease-out;
    -moz-transition: all 500ms ease-out;
    -o-transition: all 500ms ease-out;
    transition: all 500ms ease-out;

    @media (max-width: 992px) {
        flex-direction: column;
        right: 10px;
    }
`;
const NavItem = styled(Link)`
    z-index: 2;
    border: none;
    cursor: pointer;
    outline: inherit;
    color: white;
    text-transform: uppercase;
    border-bottom: 0px;
    padding: 0 16px;
    color: ${props => (props.selected ? '#ff4729' : '#ffffff')};
    background-color: transparent;
    font-family: var(--font-family-medium);
    font-size: 0.75rem;
    letter-spacing: 0.2rem;
    text-decoration: none;

    :hover {
        color: var(--primary);
    }

    @media (max-width: 992px) {
        padding-bottom: 6px;
        padding-left: ${props => (props.selected ? '8px' : '')};
        width: 120px;
    }
`;
const HeaderLogo = styled.img`
    position: absolute;
    left: 30px;
    width: 200px;
    top: ${props => props.hidenav};
    z-index: 2;

    -webkit-transition: all 500ms ease-out;
    -moz-transition: all 500ms ease-out;
    -o-transition: all 500ms ease-out;
    transition: all 500ms ease-out;

    @media (max-width: 992px) {
        width: 150px;
    }
`;

//Buttons
const ControlButtonLeft = styled(Link)`
    left: ${props => props.hidenav};
    :before,
    :after {
        background: ${props => (props.hidden ? 'transparent' : '')};
    }

    -webkit-transition: all 500ms ease-out;
    -moz-transition: all 500ms ease-out;
    -o-transition: all 500ms ease-out;
    transition: all 500ms ease-out;
`;
const ControlButtonRight = styled(Link)`
    right: ${props => props.hidenav};

    -webkit-transition: all 500ms ease-out;
    -moz-transition: all 500ms ease-out;
    -o-transition: all 500ms ease-out;
    transition: all 500ms ease-out;
`;
const LandingControlButton = styled(Link)`
    font-family: var(--font-family-bold);
    position: fixed;
    bottom: 50px;
    right: 50px;
    border: 3px solid var(--primary);
    color: var(--primary);
    font-size: 1.5rem;
    line-height: 1;
    padding: 12px 12px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    transform: perspective(1px) translateZ(0);
    -webkit-transform: perspective(1px) translateZ(0);
    transition: all 500ms ease-out;
    -webkit-transition: all 500ms ease-out;
    -moz-transition: all 500ms ease-out;
    -o-transition: all 500ms ease-out;
    transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    transition-property: color;
    -webkit-transition-property: color;
    max-width: 180px;
    display: flex;
    align-items: justify;
    justify-content: space-evenly;
    width: 100%;

    :before {
        background: var(--primary);
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }

    :hover,
    :focus {
        color: white;

        :before {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
            position: absolute;
        }

        svg {
            transition: fill 0.3s ease-out;
            fill: white;
        }
    }

    @media (min-width: 992px) {
        font-size: 1.75rem;
    }

    @media (max-width: 520px) {
        visibility: visible;
    }
`;
const Arrow = styled.span`
    background-image: url(${props => props.background});
    background-position: center;
    background-size: cover;
    height: 1.1rem;
    width: 1.1rem;
    fill: var(--primary);

    @media (min-width: 992px) {
        height: 1.3rem;
        width: 1.3rem;
    }
`;
