import React, { useState, useEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import AwesomeSlider from 'react-awesome-slider';
import Styles from 'react-awesome-slider/src/styles';
import { AppContext } from 'components/Home';
import Loader from 'components/Loader';

import rightArrow from 'assets/right_arrow.svg';
import leftArrow from 'assets/left_arrow.svg';

const client = axios.create({
    baseURL: 'https://staging.twentyfour7.com/wp-json/wp/v2'
});

const LifeFeed = () => {
    const { setShowModal, setModalContent } = useContext(AppContext);
    const [lifeFeed, setLifeFeed] = useState([]);

    useEffect(() => {
        async function getLife() {
            try {
                const response = await client.get(
                    '/event?_fields=id,featured_media,title,acf,_links,_embedded&_embed&per_page=100'
                );
                setLifeFeed(response.data);
            } catch (err) {
                console.log(err);
            }
        }
        getLife();
    }, []);

    return (
        <Slider
            bullets={false}
            cssModule={Styles}
            organicArrows={false}
            buttonContentRight={<Arrow background={rightArrow} />}
            buttonContentLeft={<Arrow background={leftArrow} />}
        >
            {lifeFeed.length > 0 ? (
                lifeFeed.slice(0, 7).map(event => (
                    <Tile key={event.id}>
                        {event.featured_media != '0' ? (
                            <FeaturedImage
                                src={
                                    event._embedded['wp:featuredmedia'][0]
                                        .source_url
                                }
                                alt={event.title.rendered}
                            />
                        ) : (
                            <FeaturedVideo src={event.acf.video_link} />
                        )}
                        <SliderInfoContainer>
                            <Title>{event.title.rendered}</Title>
                            <Description>
                                {event.acf.slider_excerpt}
                            </Description>
                            <ModalTrigger
                                onClick={() => (
                                    setShowModal(true),
                                    setModalContent(
                                        <>
                                            <Title>
                                                {event.title.rendered}
                                            </Title>
                                            {event.featured_media != '0' ? (
                                                <FeaturedImage
                                                    src={
                                                        event._embedded[
                                                            'wp:featuredmedia'
                                                        ][0].source_url
                                                    }
                                                    alt={event.title.rendered}
                                                />
                                            ) : (
                                                <FeaturedVideo
                                                    src={event.acf.video_link}
                                                />
                                            )}
                                            <Description
                                                dangerouslySetInnerHTML={{
                                                    __html: event.acf
                                                        .description
                                                }}
                                            />
                                        </>
                                    )
                                )}
                            >
                                + MORE
                            </ModalTrigger>
                        </SliderInfoContainer>
                    </Tile>
                ))
            ) : (
                <Loader />
            )}
        </Slider>
    );
};

export default LifeFeed;

const Slider = styled(AwesomeSlider)`
    width: 80vw;
    height: 50vw;
    max-width: var(--xlarge);
    min-height: 500px;
    max-height: 730px;

    @media (min-width: 668px) {
      min-height: 600px;
    }

    @media (min-width: 768px) {
      min-height: 700px;
    }

    .awssld__content {
        align-items: flex-start;
        height: auto;
        min-height: 500px;
        max-height: 730px;

        @media (min-width: 668px) {
          min-height: 600px;
        }

        @media (min-width: 768px) {
          min-height: 700px;
        }
    }

    .awssld__bullets button {
        margin: 5px 12px;
    }

    .awssld__controls {
        button {
            align-items: center;
            height: 3%;
        }

        .awssld__next {
            right: -30px;
            margin-right: 0px;
        }

        .awssld__prev {
            left: -30px;
            margin-left: 0px;
        }

        @media (max-width: 1024px) {
            visibility: hidden;
        }
    }
`;
const Tile = styled.div`
    height: 100%;
`;
const FeaturedImage = styled.img`
    width: 100%;
    max-width: 970px;

    @media (min-width: 992px) {
        width: 70vw;
    }
`;
const FeaturedVideo = styled.iframe`
    width: 100%;
    height: 23vw;
    background-color: transparent;
    border: 0px none transparent;
    padding: 0px;
    overflow: hidden;
`;
const Title = styled.h1`
    color: var(--dk-gray);
    font-size: 2.25rem;
    text-align: left;
    margin: 1.5rem auto 1rem auto;
    max-width: var(--small);
    width: 90%;
`;
const Description = styled.p`
    margin: 1rem auto;
    max-width: var(--small);
    text-align: left;
    width: 90%;

    iframe {
        display: none;
    }
`;
const Arrow = styled.span`
    background-image: url(${props => props.background});
    height: 25px;
    width: 25px;

    :hover {
        filter: invert(38%) sepia(100%) saturate(2797%) hue-rotate(344deg)
            brightness(101%) contrast(102%);
    }
`;
const ModalTrigger = styled.p`
    color: var(--primary);
    margin: 0 auto 1rem auto;
    max-width: var(--small);
    text-align: left;
    width: 90%;

    :hover {
      cursor: pointer;
      color: var(--dk-gray);
`;
const SliderInfoContainer = styled.div`
    height: 165px;
`;
