import React, { useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from 'components/Home';

const Modal = () => {
    const { showModal, setShowModal, modalContent } = useContext(AppContext);

    return showModal ? (
        <ModalContainer>
            <Overlay onClick={() => setShowModal(false)} />
            <Container>
                <CloseButton
                    position={'top'}
                    onClick={() => setShowModal(false)}
                >
                    <CloseIcon>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 30 30"
                        >
                            <polygon points="30 2.12 27.88 0 15 12.88 2.12 0 0 2.12 12.88 15 0 27.88 2.12 30 15 17.12 27.88 30 30 27.88 17.12 15 30 2.12"></polygon>
                        </svg>
                    </CloseIcon>
                </CloseButton>
                <ModalContent>{modalContent}</ModalContent>
                <CloseButton
                    position={'bottom'}
                    onClick={() => setShowModal(false)}
                >
                    <CloseIcon>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 30 30"
                        >
                            <title>Exit</title>
                            <polygon points="30 2.12 27.88 0 15 12.88 2.12 0 0 2.12 12.88 15 0 27.88 2.12 30 15 17.12 27.88 30 30 27.88 17.12 15 30 2.12"></polygon>
                        </svg>
                    </CloseIcon>
                </CloseButton>
            </Container>
        </ModalContainer>
    ) : null;
};

export default Modal;

const ModalContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3000;
    width: 100vw;
    height: 100vh;
    animation: fadeIn 0.3s;

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

const Overlay = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(27, 27, 27, 0.97);
    display: block;
    position: absolute;
    z-index: 3000;
`;
const Container = styled.div`
    height: 100vh;
    width: 100vw;
    background-color: var(--lt-gray);
    display: flex;
    flex-direction: column;
    align-content: center;
    overflow: auto;
    z-index: 3001;
    max-width: var(--xlarge);

    //Centers modal
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
const CloseButton = styled.button`
    border: none;
    height: 25px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    padding: ${props =>
        props.position == 'top' ? '50px 50px 0 0' : '0 0 80px 0'};
    margin: ${props =>
        props.position == 'top' ? '0 0 50px auto' : '50px auto 0 auto'};

    &:hover {
        cursor: pointer;
    }
`;
const CloseIcon = styled.span`
    background-image: url(${props => props.background});
    background-position: center;
    background-size: cover;
    height: 25px;
    width: 25px;
    fill: #a69b9e;

    :hover {
        fill: #231f20;
    }

    -webkit-transition: all 500ms ease-out;
    -moz-transition: all 500ms ease-out;
    -o-transition: all 500ms ease-out;
    transition: all 500ms ease-out;
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: flex-start;
    max-width: var(--medium);
    margin: auto;
    padding: 0 10px;

    h1,
    p {
        width: 100%;
    }
`;
