import React from 'react';
import ReactDOM from 'react-dom';
import Home from 'components/Home';

const ROOT_ELEMENT = document.getElementById('root');

const App = () => {
    return <Home />;
};
ReactDOM.render(<App />, ROOT_ELEMENT);
