import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import instaIcon from 'assets/icon_social_instagram.svg';

const { INSTAGRAM_ACCESS_TOKEN } = process.env;

const InstaFeed = () => {
    const [insta, setInsta] = useState([]);
    const [nextCall, setNextCall] = useState();
    const [moreLoaded, setMoreLoaded] = useState(0);

    const token = INSTAGRAM_ACCESS_TOKEN;

    useEffect(() => {
        const url = `https://graph.instagram.com/me/media?fields=media_type,permalink,media_url,thumbnail_url&limit=9&access_token=${token}`;

        const options = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            }
        };
        fetch(url, options)
            .then(data => data.json())
            .then(data => {
                const photosArray = data.data;
                const photosPaging = data.paging;
                setInsta(photosArray);
                setNextCall(photosPaging.next);
            });
    }, []);

    useEffect(() => {
        if (moreLoaded > 0) {
            const urlNext = nextCall;

            const options = {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            };
            fetch(urlNext, options)
                .then(data => data.json())
                .then(data => {
                    const photosArrayNext = data.data;
                    const photosPagingNext = data.paging;
                    setInsta(insta.concat(photosArrayNext));
                    setNextCall(photosPagingNext.next);
                });
        }
    }, [moreLoaded]);

    useEffect(() => {
        setMoreLoaded(false);
    }, []);

    return (
        <>
            <InstaFeedTitle>
                <InstaIcon src={instaIcon} alt="instagram icon" /> | @TF7DESIGN
            </InstaFeedTitle>
            <Grid>
                {insta.map((media, idx) => (
                    <Permalink
                        href={media.permalink}
                        target="_blank"
                        rel="noopener"
                        aria-label={`${media.id}-${idx}`}
                        key={`${media.id}-${idx}`}
                        background={
                            media.thumbnail_url
                                ? media.thumbnail_url
                                : media.media_url
                        }
                    />
                ))}
            </Grid>
            {nextCall !== undefined && (
                <BrandButton onClick={() => setMoreLoaded(moreLoaded + 1)}>
                    Load More
                </BrandButton>
            )}
        </>
    );
};

export default InstaFeed;

const Grid = styled.div`
    width: 100%;
    margin: 0 auto 50px auto;
    display: grid;
    grid-gap: 10px;
    justify-content: center;
    max-width: var(--xlarge);

    @media (min-width: 768px) {
        grid-template-columns: 25vw 25vw 25vw;
    }

    @media (min-width: 1024px) {
        grid-template-columns: 20vw 20vw 20vw;
    }
`;
const Permalink = styled.a`
    height: 80vw;
    width: 80vw;
    background: url(${props => props.background});
    background-position: center;
    background-size: cover;
    justify-self: center;

    @media (min-width: 768px) {
        height: 25vw;
        width: 25vw;
    }

    @media (min-width: 1024px) {
        height: 20vw;
        width: 20vw;
    }
`;
const BrandButton = styled.a`
    font-family: var(--font-family-bold);
    padding: 12px 28px;
    font-size: 1.75rem;
    border: 3px solid var(--primary);
    transition: all 500ms ease-out;
    text-align: center;
    text-transform: uppercase;
    line-height: 1;
    text-decoration: none;
    background: var(--primary);
    color: white;
    cursor: pointer;
    max-width: 200px;

    :hover,
    :focus {
        background-color: var(--primary-hover);
        border: 3px solid var(--primary-hover);
    }
`;
const InstaFeedTitle = styled.h2`
    color: var(--dk-gray);
    font-family: var(--font-family-medium-condensed);
    line-height: 1;
    margin-bottom: 3.75rem;
`;
const InstaIcon = styled.img`
    height: 45px;
    width: 45px;
    vertical-align: top;
`;
