import React, { useEffect, useState, useRef, useContext } from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { AppContext } from 'components/Home';
import Advance from 'components/BouncingArrow';
import Footer from './Footer';

const CareersPage = () => {
    const { setHideNav } = useContext(AppContext);
    const { ref, inView } = useInView({
        threshold: 0.1
    });

    useEffect(() => {
        setHideNav(inView);
    }, [inView]);

    const [height, setHeight] = useState();
    const iFrameRef = useRef();

    useEffect(() => {
        setHeight(iFrameRef.current.offsetTop + 600 + 'px');
    }, []);

    return (
        <Container>
            <Panel>
                <LargeMessage>
                    No great ideas are ever realized without the{' '}
                    <span>talent</span> to support them.
                </LargeMessage>
            </Panel>
            <Advance />
            <SubPanelParallax />
            <SubPanel ref={ref}>
                <SubPanelContent>
                    <LargeMessage color={'var(--dk-gray)'}>
                        <LookingMessage>PRESENTLY LOOKING FOR:</LookingMessage>
                        <SmallMessage>
                            Curious self-starters with a blend of ego and
                            humility who can confidently navigate a highly
                            entrepreneurial environment. If you’re fearless,
                            flexible, and a little weird, take a look at our
                            openings below. We want to know what drives you to
                            create.
                        </SmallMessage>
                        <JobsiFrameContainer
                            id="iframeHeightDiv"
                            name="HRM Direct Career Site iFrame Container"
                        >
                            <JobsiFrame
                                ref={iFrameRef}
                                adjustedHeight={height}
                                id="inlineframe"
                                name="Career Site iFrame"
                                sandbox="allow-top-navigation allow-scripts allow-forms allow-popups allow-same-origin"
                                src="https://recruiting.paylocity.com/recruiting/jobs/All/c4e80bf9-4713-497a-83f6-296cb54093ed/TWENTY-FOUR-7-INC"
                                frameborder="0"
                                allowtransparency="true"
                                title="Career Site"
                            />
                        </JobsiFrameContainer>
                    </LargeMessage>
                </SubPanelContent>
                <Footer />
            </SubPanel>
        </Container>
    );
};

export default CareersPage;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
    height: 100vh;
    width: 100vw;
`;
const Panel = styled.div`
    margin: auto;
    position: fixed;
`;
const LargeMessage = styled.h1`
    color: ${props => props.color};
    text-align: center;
    margin: auto;
    align-items: center;
    max-width: 67%;

    @media (min-width: 1200px) {
        max-width: 50%;
    }

    span {
        color: var(--primary);
    }
`;

//Subpanel
const SubPanelParallax = styled.div`
    width: 100vw;
    height: 100vh;
    background: transparent;
    position: absolute;
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  /* Turn off parallax scrolling for tablets and phones. Increase the pixels if needed */
  @media only screen and (max-device-width: 1366px) {
    .parallax {
      background-attachment: scroll;
    }
`;
const SubPanel = styled.div`
    position: absolute;
    overflow: auto;
    width: 100vw;
    background-color: var(--lt-gray);
    left: 0;
    top: 100vh;
`;
const SubPanelContent = styled.div`
    padding-top: 4rem;
    width: 100vw;
    height: 100%;
    background: ${props => props.background};
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;

    @media (min-width: 1024px) {
        padding-top: 6rem;
    }

    h1 {
        font-family: var(--font-family-medium-condensed);
        max-width: 80%;
        text-align: left;

        @media (min-width: 1200px) {
            max-width: var(--xlarge);
            width: 67%;
        }
    }
`;

const LookingMessage = styled.p`
    border-bottom: 2px solid var(--dk-gray);
    display: initial;
    font-family: var(--font-family-medium-condensed);
    font-size: 1.75rem;
    line-height: 1.3;
    margin-bottom: 1.25rem;
    text-align: left;

    @media (min-width: 768px) {
        font-size: 2rem;
        line-height: 1.2;
    }

    @media (min-width: 1024px) {
        font-size: 2.25rem;
    }
`;
const SmallMessage = styled.p`
    text-align: left;
    text-transform: initial;
    width: 100%;
    margin: 3rem auto 0 auto;

    @media (min-width: 1024px) {
        font-size: 1.125rem;
        max-width: 67%;
    }
`;
const JobsiFrameContainer = styled.div`
    margin-bottom: 50px;
`;
const JobsiFrame = styled.iframe`
    width: 100%;
    height: ${props => props.adjustedHeight};
    border: none;
`;
