import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { AppContext } from 'components/Home';
import Advance from 'components/BouncingArrow';
import InstaFeed from '../components/Instagram';
import LifeFeed from '../components/LifeFeed';
import Footer from './Footer';

const LifePage = () => {
    const { setHideNav } = useContext(AppContext);
    const { ref, inView } = useInView({
        threshold: 0.04
    });

    useEffect(() => {
        setHideNav(inView);
    }, [inView]);

    return (
        <Container>
            <Panel>
                <LargeMessage>
                    WE DON’T SEPARATE WORK AND LIFE. WHAT INSPIRES AND EXCITES
                    EACH OF US IS SHARED AND CELEBRATED 24 HOURS A DAY, 7 DAYS A
                    WEEK. <span>ONE LIFE.</span>
                </LargeMessage>
            </Panel>
            <Advance />
            <SubPanelParallax />
            <SubPanel ref={ref}>
                <SubPanelContent>
                    <LifeFeed />
                    <InstaFeed />
                </SubPanelContent>
                <Footer />
            </SubPanel>
        </Container>
    );
};

export default LifePage;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 100vh;
    width: 100vw;
`;
const Panel = styled.div`
    margin: auto;
    position: fixed;
`;
const LargeMessage = styled.h1`
    color: ${props => props.color};
    text-align: center;
    margin: auto;
    align-items: center;
    max-width: 67%;

    @media (min-width: 1200px) {
        max-width: 50%;
    }

    span {
        color: var(--primary);
    }
`;

//Subpanel
const SubPanelParallax = styled.div`
    width: 100vw;
    height: 100vh;
    background: transparent;
    position: absolute;
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  /* Turn off parallax scrolling for tablets and phones. Increase the pixels if needed */
  @media only screen and (max-device-width: 1366px) {
    .parallax {
      background-attachment: scroll;
    }
`;
const SubPanel = styled.div`
    position: absolute;
    overflow: auto;
    width: 100vw;
    background-color: var(--lt-gray);
    left: 0;
    top: 100vh;
`;
const SubPanelContent = styled.div`
    padding-bottom: 4rem;
    padding-top: 4rem;
    width: 100vw;
    height: 100%;
    background: ${props => props.background};
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;

    @media (min-width: 1024px) {
        padding-top: 6rem;
    }
`;
