import React from 'react';
import styled from 'styled-components';

import bgLanding from 'assets/TF7-overlay.svg';

const LandingPage = () => {
    return (
        <Container>
            <SectionContent src={bgLanding} alt="TF7" />
        </Container>
    );
};

export default LandingPage;

const Container = styled.div`
    color: white;
`;

const SectionContent = styled.img`
    margin: auto;
    width: 100vw;
    position: relative;

    @media (max-width: 600px) {
        height: 100vh;
    }
`;
