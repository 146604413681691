import React from 'react';
import styled from 'styled-components';

const Advance = () => {
    return (
        <AdvanceArrow>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                <polygon points="0 21.44 2.12 23.56 15 10.68 27.88 23.56 30 21.44 15 6.44 0 21.44"></polygon>
            </svg>
        </AdvanceArrow>
    );
};

export default Advance;

const AdvanceArrow = styled.div`
    height: 40px;
    width: 40px;
    fill: var(--primary);
    margin: 80vh auto 0;
    position: fixed;

    @media (max-width: 600px) {
        margin: 35vh auto 0;
    }

    @media screen and (hover: none) {
        margin: 35vh auto 0;
    }

    animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    -moz-animation: bounce 2s infinite;
    -o-animation: bounce 2s infinite;

    @keyframes bounce {
        0%,
        50%,
        100% {
            transform: rotate(180deg) translateY(0);
        }

        25%,
        75% {
            transform: rotate(180deg) translateY(-10px);
        }
    }
`;
