import React from 'react';
import styled from 'styled-components';

import preLoader from 'assets/running_deer.png';

const Loader = () => {
    return <Preloader src={preLoader} alt="campfire animation" />;
};

export default Loader;

const Preloader = styled.img`
    height: 150px;
    width: 150px;
`;
