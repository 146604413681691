import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { AppContext } from 'components/Home';
import Advance from 'components/BouncingArrow';
import Footer from './Footer';

import bg2 from 'assets/bg_approach_2_large.jpeg';

const AboutPage = () => {
    const { setHideNav } = useContext(AppContext);
    const { ref, inView } = useInView({
        threshold: 0.15
    });

    useEffect(() => {
        setHideNav(inView);
    }, [inView]);

    return (
        <Container>
            <Panel>
                <LargeMessage>
                    WE <span>IGNITE LOYALTY.</span> IT’S THE BRAND CAPITAL THAT
                    SPARKS MOVEMENTS AND FUELS RELATIONSHIPS THAT ARE COVETED,
                    CHAMPIONED AND FIERCELY PROTECTED.
                </LargeMessage>
            </Panel>
            <Advance />
            <SubPanelParallax />
            <SubPanel ref={ref}>
                <SubPanelContent>
                    <LargeMessage color={'var(--dk-gray)'} ref={ref}>
                        WE LISTEN FIRST – CULTIVATING A CULTURE OF THOUGHT
                        LEADERSHIP WHERE DATA IS NON-NEGOTIABLE,{' '}
                        <span>STRATEGY IS KING</span> AND EVERYONE’S VOICE
                        COUNTS. OUR CROSS-FUNCTIONAL TEAM HAS THE FLEXIBILITY
                        AND FORTITUDE TO CONSISTENTLY EXCEED EXPECTATIONS.
                    </LargeMessage>
                </SubPanelContent>
                <SubPanelContent background={`url(${bg2})`}>
                    <LargeMessage>
                        CONSUMER INSIGHTS DRIVE{' '}
                        <span>CREATIVE – IN ANY FORM –</span> INCLUDING THINGS
                        YOU NEVER THOUGHT POSSIBLE. SHAPING EXPERIENCES THAT WIN
                        HEARTS AND MINDS. GROW BRANDS. CHANGE LANDSCAPES.
                    </LargeMessage>
                </SubPanelContent>
                <Footer />
            </SubPanel>
        </Container>
    );
};

export default AboutPage;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 100vh;
    width: 100vw;
`;
const Panel = styled.div`
    margin: auto;
    position: fixed;
`;
const LargeMessage = styled.h1`
    color: ${props => props.color};
    text-align: center;
    margin: auto;
    align-items: center;
    max-width: 67%;

    @media (min-width: 1200px) {
        max-width: 50%;
    }

    span {
        color: var(--primary);
    }
`;

//Subpanel
const SubPanelParallax = styled.div`
    width: 100vw;
    height: 100vh;
    background: transparent;
    position: absolute;
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  /* Turn off parallax scrolling for tablets and phones. Increase the pixels if needed */
  @media only screen and (max-device-width: 1366px) {
    .parallax {
      background-attachment: scroll;
    }
`;
const SubPanel = styled.div`
    position: absolute;
    overflow: auto;
    width: 100vw;
    background-color: var(--lt-gray);
    left: 0;
    top: 100vh;
`;
const SubPanelContent = styled.div`
    width: 100vw;
    height: 100vh;
    background: ${props => props.background};

    h1 {
        @media (min-width: 1200px) {
            max-width: 67%;
        }
    }

    //temp
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;

    h1 {
        @media (min-width: 1200px) {
            max-width: 67%;
        }
    }
`;
