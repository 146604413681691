import React from 'react';
import styled from 'styled-components';

const ContactPage = () => {
    return (
        <Container>
            <Panel>
                <LargeMessage>
                    <span>RING BELL</span> FOR SERVICE.
                </LargeMessage>
            </Panel>
        </Container>
    );
};

export default ContactPage;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 100vh;
    width: 100vw;
`;
const Panel = styled.div`
    margin: auto;
    position: fixed;
`;
const LargeMessage = styled.h1`
    color: ${props => props.color};
    text-align: center;
    margin: auto;
    align-items: center;
    max-width: 67%;
    min-width: 125px;

    @media (min-width: 1200px) {
        max-width: 50%;
        min-width: 275px;
    }

    span {
        color: var(--primary);
    }
`;
